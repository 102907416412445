import Layout from "@/layout/PageLayout.vue";

export default {
  path: "/sphere",
  name: "sphere",
  component: Layout,
  meta: { title: "球型机器人", icon: "Compass" },
  children: [
    {
      path: "sphere-robot-manage",
      name: "sphere-robot-manage",
      component: () => import("@/views/sphere/RoborManage.vue"),
      meta: { title: "机器人管理", icon: "Management" },
    },
  ],
};
