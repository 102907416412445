/*
 * @Author: reiner850593913 103234074+reiner850593913@users.noreply.github.com
 * @Date: 2022-05-09 11:21:37
 * @LastEditors: reiner850593913 lk850593913@gmail.com
 * @LastEditTime: 2022-09-03 23:34:45
 * @FilePath: \robot-management-web\src\store\modules\tagsview.js
 * @Description: 标签页相关的状态管理模块
 */
import { defineStore } from "pinia";
import { ref } from "vue";
import remove from "lodash/remove";

export const useTagsViewStore = defineStore("tagsView", () => {
  const visitedViews = ref([]);

  /**
   * @description: 添加访问过的路由
   * @param {*} view 路由对象
   * @return {*}
   */
  function addVisitedView(view) {
    if (visitedViews.value.some((v) => v.path === view.path)) return;
    if (view.name === "redirect") return;
    visitedViews.value.push(
      Object.assign({}, view, { title: view.meta.title || "no-name" })
    );
  }

  /**
   * @description: 删除访问过的路由
   * @param {*} view 路由对象
   * @return {*}
   */
  function delVisitedView(view) {
    remove(visitedViews.value, (item) => item.path === view.path);
  }

  /**
   * @description: 删除其他路由
   * @param {*} view 当前要保留的路由
   * @return {*}
   */
  function delOthersViews(view) {
    remove(visitedViews.value, (item) => {
      if (item.meta && item.meta.affix) {
        return false;
      }
      if (item.path !== view.path) {
        return true;
      } else {
        return false;
      }
    });
  }

  /**
   * @description: 删除所有路由
   * @param {*} view
   * @return {*}
   */
  function delAllViews() {
    visitedViews.value = [];
  }

  return {
    visitedViews,
    addVisitedView,
    delVisitedView,
    delOthersViews,
    delAllViews,
  };
});
