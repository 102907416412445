<!--
 * @Author: ReinerLau lk850593913@gmail.com
 * @Date: 2022-08-23 10:45:57
 * @LastEditors: ReinerLau lk850593913@gmail.com
 * @LastEditTime: 2022-10-17 14:26:36
 * @FilePath: \robot\src\layout\components\Sidebar\SidebarItem.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <template v-if="!item.meta.hidden">
    <template v-if="hasChildren">
      <el-sub-menu :index="item.name">
        <template #title>
          <el-icon>
            <component :is="item.meta.icon" v-if="item.meta.icon" />
          </el-icon>
          <span>{{ item.meta.title }}</span>
        </template>
        <sidebar-item
          v-for="child in item.children"
          :key="child.name"
          :item="child"
        />
      </el-sub-menu>
    </template>
    <template v-else>
      <router-link :to="{ name: item.name }">
        <el-menu-item :index="item.name">
          <el-icon>
            <component :is="item.meta.icon" v-if="item.meta.icon" />
          </el-icon>
          <template #title>
            <span>{{ item.meta.title }}</span>
          </template>
        </el-menu-item>
      </router-link>
    </template>
  </template>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const hasChildren = computed(() => {
  return props.item.children && props.item.children.length > 0;
});
</script>

<style>
.el-menu .i-icon svg {
  margin-bottom: 4px;
  margin-right: 16px;
}
.el-menu span {
  font-weight: bold;
}
#app
  .hideSidebar
  .el-menu--collapse
  .el-submenu
  > .el-submenu__title
  > span:first-child {
  overflow: unset;
  visibility: unset;
  display: block;
}
</style>
