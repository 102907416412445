/*
 * @Author: reiner850593913 lk850593913@gmail.com
 * @Date: 2022-09-10 10:46:39
 * @LastEditors: ReinerLau lk850593913@gmail.com
 * @LastEditTime: 2023-02-15 10:50:48
 * @FilePath: \robot-management-web\src\router\modules\patroling.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Layout from "@/layout/PageLayout.vue";

export default {
  path: "/patroling",
  name: "patroling",
  component: Layout,
  meta: { title: "围界车", icon: "Position" },
  children: [
    {
      path: "release",
      name: "patroling-control-release",
      component: () => import("@/views/patroling/PatrolingControl.vue"),
      meta: {
        title: "操控端",
        icon: "Location",
      },
    },
  ],
};
