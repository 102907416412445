/*
 * @Author: hye0228 1261769443@qq.com
 * @Date: 2022-10-09 13:02:36
 * @LastEditors: ReinerLau lk850593913@gmail.com
 * @LastEditTime: 2022-11-04 14:10:35
 * @FilePath: \v2.0.0\src\router\modules\admin.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Layout from "@/layout/PageLayout.vue";

export default {
  path: "/admin",
  name: "admin",
  component: Layout,
  meta: { title: "通用管理", icon: "Operation" },
  children: [
    {
      path: "authority",
      name: "admin-authority",
      meta: {
        title: "权限管理",
        icon: "Key",
      },
      children: [
        {
          path: "user",
          name: "admin-authority-user",
          component: () => import("@/views/admin/authority/UserList.vue"),
          meta: {
            title: "用户列表",
            icon: "User",
          },
        },
        {
          path: "role",
          name: "admin-authority-role",
          component: () => import("@/views/admin/authority/RoleList.vue"),
          meta: {
            title: "角色列表",
            icon: "UserFilled",
          },
        },
        {
          path: "menu",
          name: "admin-authority-menu",
          component: () => import("@/views/admin/authority/MenuList.vue"),
          meta: {
            title: "菜单列表",
            icon: "Expand",
          },
        },
      ],
    },
    {
      path: "camera",
      name: "camera",
      component: () => import("@/views/admin/CameraList.vue"),
      meta: {
        title: "摄像头管理",
        icon: "VideoCameraFilled",
      },
    },
    {
      path: "device",
      name: "device",
      component: () => import("@/views/admin/DeviceManagement.vue"),
      meta: { title: "外设管理", icon: "Setting" },
    },
    {
      path: "admin-company",
      name: "admin-company",
      component: () => import("@/views/admin/company/CompanyManagement.vue"),
      meta: { title: "单位管理", icon: "Setting" },
    },
    {
      path: "admin-system-configuration",
      name: "admin-system-configuration",
      component: () => import("@/views/admin/SystemConfiguration.vue"),
      meta: { title: "系统配置", icon: "Setting" },
    },
    {
      path: "admin-official-account-configuration",
      name: "admin-official-account-configuration",
      component: () => import("@/views/admin/OfficialAccountConfiguration.vue"),
      meta: { title: "公众号配置", icon: "ChatDotRound" },
    },
    {
      path: "admin-wx-abnormal",
      name: "admin-wx-abnormal",
      component: () => import("@/views/admin/WxAbnormal.vue"),
      meta: { title: "微信预警", icon: "WarningFilled" },
    },
  ],
};
