/*
 * @Author: ReinerLau lk850593913@gmail.com
 * @Date: 2022-08-23 10:45:57
 * @LastEditors: reiner850593913 lk850593913@gmail.com
 * @LastEditTime: 2022-09-04 00:51:30
 * @FilePath: \robot\src\store\modules\settings.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { defineStore } from "pinia";
import { ref } from "vue";
import defaultSettings from "@/utils/settings.js";

export const useSettingStore = defineStore("setting", () => {
  const fixedHeader = ref(defaultSettings.fixedHeader);

  const sidebarLogo = ref(defaultSettings.sidebarLogo);

  return {
    fixedHeader,
    sidebarLogo,
  };
});
// import defaultSettings from '@/settings'

// const { showSettings, fixedHeader, sidebarLogo } = defaultSettings

// const state = {
//   showSettings: showSettings,
//   fixedHeader: fixedHeader,
//   sidebarLogo: sidebarLogo
// }

// const mutations = {
//   CHANGE_SETTING: (state, { key, value }) => {
//     // eslint-disable-next-line no-prototype-builtins
//     if (state.hasOwnProperty(key)) {
//       state[key] = value
//     }
//   }
// }

// const actions = {
//   changeSetting({ commit }, data) {
//     commit('CHANGE_SETTING', data)
//   }
// }

// export default {
//   namespaced: true,
//   state,
//   mutations,
//   actions
// }
