/*
 * @Author: ReinerLau lk850593913@gmail.com
 * @Date: 2022-08-23 10:45:57
 * @LastEditors: reiner850593913 lk850593913@gmail.com
 * @LastEditTime: 2022-09-04 00:55:59
 * @FilePath: \robot\src\store\permission.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { defineStore } from "pinia";
import { ref } from "vue";
import { getResource } from "@/api/user.js";
import { getToken } from "@/utils/auth.js";

export const usePermissionStore = defineStore("permission", () => {
  const btnPermissions = ref("");

  async function getBtnPermission() {
    const token = getToken();
    if (!token) return;
    const { data } = await getResource(token);
    btnPermissions.value = data || [];
  }

  return {
    btnPermissions,
    getBtnPermission,
  };
});
