import Layout from "@/layout/PageLayout.vue";

export default {
  path: "/agv",
  name: "agv",
  component: Layout,
  meta: { title: "北矿运输", icon: "Cherry" },
  children: [
    {
      path: "path-setting",
      name: "path-setting",
      component: () => import("@/views/agv/PathSetting.vue"),
      meta: {
        title: "路径规划",
        icon: "Umbrella",
      },
    },
  ],
};
