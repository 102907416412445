<!--
 * @Author: ReinerLau lk850593913@gmail.com
 * @Date: 2022-09-26 14:21:06
 * @LastEditors: ReinerLau lk850593913@gmail.com
 * @LastEditTime: 2022-09-26 15:14:52
 * @FilePath: \robot\src\components\SvgIcon.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <svg>
    <use :xlink:href="symbolId" />
  </svg>
</template>
<script setup>
import { computed } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

const symbolId = computed(() => `#icon-${props.name}`);
</script>
