/*
 * @Author: 明明 343438055@qq.com
 * @Date: 2022-05-24 08:51:10
 * @LastEditors: ReinerLau lk850593913@gmail.com
 * @LastEditTime: 2022-09-01 16:02:24
 * @FilePath: \robot-management-web\src\components\utils\has.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { usePermissionStore } from "@/store/permission.js";
import { storeToRefs } from "pinia";

/**
 * @description: 注册 v-has 按钮权限控制指令
 * @param {*} app vue实例
 * @return {*}
 */
function registerHas(app) {
  app.directive("has", {
    mounted(el, binding) {
      const { btnPermissions } = storeToRefs(usePermissionStore());
      const btnPermissionValue = binding.value;
      if (!btnPermissions.value.includes(btnPermissionValue)) {
        el.parentNode.removeChild(el);
      }
    },
  });
}

export default registerHas;
