import Layout from "@/layout/PageLayout.vue";

export default {
  path: "/quadruped",
  name: "quadruped",
  component: Layout,
  meta: { title: "足式机器人", icon: "Place" },
  children: [
    {
      path: "eight-quadruped-control",
      name: "eight-quadruped-control",
      component: () => import("@/views/quadruped/EightQuadrupedControl.vue"),
      meta: { title: "8KG幽灵蛛", icon: "Management" },
    },
    {
      path: "form-management",
      name: "foot-form-management",
      component: () => import("@/views/quadruped/FormManagement.vue"),
      meta: {
        title: "表单管理",
        icon: "Folder",
      },
    },
    {
      path: "form-create-data",
      name: "foot-form-create-data",
      component: () =>
        import("@/views/quadruped/components/FormCreateData.vue"),
      meta: {
        title: "表单数据",
        icon: "Edit",
        hidden: true,
      },
    },
    {
      path: "form-classification-management",
      name: "foot-form-classification-management",
      component: () =>
        import("@/views/quadruped/FormClassificationManagement.vue"),
      meta: {
        title: "表单分类管理",
        icon: "Menu",
      },
    },
  ],
};
