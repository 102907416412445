/*
 * @Author: ReinerLau lk850593913@gmail.com
 * @Date: 2022-10-17 13:28:28
 * @LastEditors: ReinerLau lk850593913@gmail.com
 * @LastEditTime: 2022-10-24 16:07:41
 * @FilePath: \v2.0.0\src\router\modules\inspection.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Layout from "@/layout/PageLayout.vue";

export default {
  path: "/inspection",
  name: "inspection",
  component: Layout,
  redirect: "/inspection/control-home",
  meta: { title: "安保机器人", icon: "Cpu" },
  children: [
    {
      path: "control-home",
      name: "inspection-control-home",
      component: () => import("@/views/inspection/InspectionControlHome.vue"),
      meta: {
        title: "操控端",
        icon: "Location",
      },
    },
    {
      path: "robot-manage",
      name: "inspection-robot-manage",
      component: () => import("@/views/inspection/InspectionRobotManage.vue"),
      meta: {
        title: "机器人管理",
        icon: "Management",
      },
    },
    {
      path: "resource-manage",
      name: "inspection-resource-manage",
      component: () =>
        import("@/views/inspection/ResourceManage/ResourceManage.vue"),
      meta: {
        title: "资源管理",
        icon: "Management",
      },
    },
    {
      path: "map-setting",
      name: "inspection-map-setting",
      component: () => import("@/views/inspection/MapSettingIndex.vue"),
      meta: {
        title: "地图设置",
        icon: "EditPen",
      },
    },
    {
      path: "control",
      name: "inspection-control",
      component: () => import("@/views/inspection/InspectionControl.vue"),
      meta: {
        title: "操控端详情",
        hidden: true,
      },
    },
  ],
};
