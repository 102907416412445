<!--
 * @Author: reiner850593913 103234074+reiner850593913@users.noreply.github.com
 * @Date: 2022-05-09 09:32:13
 * @LastEditors: ReinerLau lk850593913@gmail.com
 * @LastEditTime: 2022-09-05 16:12:46
 * @FilePath: \robot-management-web\src\layout\components\TagsView\index.vue
 * @Description: 标签页组件
-->
<template>
  <div id="tags-view-container" ref="root" class="tags-view-container">
    <scroll-pane
      ref="scrollPane"
      class="tags-view-wrapper"
      @scroll="handleScroll"
    >
      <router-link
        v-for="tag in visitedViews"
        :key="tag.path"
        :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
        class="tags-view-item"
        :class="isActive(tag) ? 'active' : ''"
        @contextmenu.prevent="openMenu(tag, $event)"
      >
        <span>{{ tag.title }}</span>
        <el-icon
          v-if="!isAffix(tag)"
          :size="15"
          @click.prevent.stop="closeSelectedTag(tag)"
        >
          <Close />
        </el-icon>
      </router-link>
    </scroll-pane>
    <ul
      v-show="visible"
      :style="{ left: left + 'px', top: top + 'px' }"
      class="contextmenu"
    >
      <li @click="refreshSelectedTag">刷新</li>
      <li v-if="!isAffix(selectedTag)" @click="closeSelectedTag(selectedTag)">
        关闭
      </li>
      <li @click="closeOthersTags">关闭其他</li>
      <li @click="closeAllTags">关闭所有</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TagsView",
};
</script>
<script setup>
import ScrollPane from "./ScrollPane.vue";
import { useTagsViewStore } from "@/store/tagsView.js";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { watch, ref } from "vue";

const tagsViewStore = useTagsViewStore();
const { visitedViews } = storeToRefs(tagsViewStore);
const { addVisitedView, delVisitedView, delOthersViews, delAllViews } =
  tagsViewStore;

const route = useRoute();
const router = useRouter();
watch(route, () => {
  addTags();
});

initTags();
addTags();

/**
 * @description: 初始化tag
 * @return {*}
 */
function initTags() {
  const affixTags = router
    .getRoutes()
    .filter((item) => item.meta && item.meta.affix);
  affixTags.forEach((tag) => {
    addVisitedView(tag);
  });
}

/**
 * @description: 添加tag
 * @return {*}
 */
function addTags() {
  const { name } = route;
  if (name) {
    addVisitedView(route);
  }
}

/**
 * @description: 判断当前tag是否高亮
 * @param {*} tag 当前tag
 * @return {*}
 */
function isActive(tag) {
  return tag.path === route.path;
}

/**
 * @description: 关闭tag
 * @return {*}
 */
function closeSelectedTag(tag) {
  delVisitedView(tag);
  if (isActive(tag)) {
    toLastView();
  }
}

/**
 * @description: 跳转到最后一个标签或首页
 * @return {*}
 */
function toLastView() {
  const latestView = visitedViews.value.slice(-1)[0];
  if (latestView) {
    router.push(latestView.path);
  } else {
    router.push("/");
  }
}

/**
 * @description: 固定tag
 * @param {*} tag 当前tag
 * @return {*}
 */
function isAffix(tag) {
  return tag.meta && tag.meta.affix;
}

// 右键菜单相关 ----------------------------------------------
// 右键菜单位置
const left = ref(0);
const top = ref(0);

const visible = ref(false);
const selectedTag = ref({});
// 组件根元素
const root = ref(null);

watch(visible, (value) => {
  // 打开后点击任意地方关闭菜单
  if (value) {
    document.body.addEventListener("click", closeMenu);
  } else {
    document.body.removeEventListener("click", closeMenu);
  }
});

/**
 * @description: 打开右键菜单
 * @param {*} tag 标签
 * @param {*} e 右键事件
 * @return {*}
 */
function openMenu(tag, e) {
  const menuMinWidth = 105;
  const offsetLeft = root.value.getBoundingClientRect().left;
  const offsetWidth = root.value.offsetWidth;
  const maxLeft = offsetWidth - menuMinWidth;
  const curLeft = e.clientX - offsetLeft + 15;

  if (curLeft > maxLeft) {
    left.value = maxLeft;
  } else {
    left.value = curLeft;
  }

  top.value = e.clientY;
  visible.value = true;
  selectedTag.value = tag;
}

/**
 * @description: 关闭右键菜单
 * @param {*}
 * @return {*}
 */
function closeMenu() {
  visible.value = false;
}

/**
 * @description: 右键菜单 - 关闭其他
 * @param {*}
 * @return {*}
 */
function closeOthersTags() {
  router.push(selectedTag.value.path);
  delOthersViews(selectedTag.value);
}
/**
 * @description: 右键菜单 - 关闭所有
 * @return {*}
 */
function closeAllTags() {
  delAllViews();
  initTags();
  toLastView();
}
/**
 * @description: 滚动关闭右键菜单
 * @param {*}
 * @return {*}
 */
function handleScroll() {
  closeMenu();
}

/**
 * @description: 右键菜单 - 刷新
 * @return {*}
 */
function refreshSelectedTag() {
  router.replace({
    path: `/redirect${selectedTag.value.path}`,
  });
}
</script>

<style lang="scss" scoped>
.tags-view-container {
  height: 34px;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #d8dce5;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
  .tags-view-wrapper {
    .tags-view-item {
      display: inline-flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      height: 26px;
      line-height: 26px;
      border: 1px solid #d8dce5;
      color: #495060;
      background: #fff;
      padding: 0 8px;
      font-size: 12px;
      margin-left: 5px;
      margin-top: 4px;
      &:first-of-type {
        margin-left: 15px;
      }
      &:last-of-type {
        margin-right: 15px;
      }
      &.active {
        background-color: #42b983;
        color: #fff;
        border-color: #42b983;
        &::before {
          content: "";
          background: #fff;
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: relative;
          margin-right: 5px;
        }
      }
    }
  }
  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
}
</style>
