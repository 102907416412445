<!--
 * @Author: reiner850593913 lk850593913@gmail.com
 * @Date: 2022-08-27 21:20:59
 * @LastEditors: ReinerLau lk850593913@gmail.com
 * @LastEditTime: 2022-09-05 08:25:26
 * @FilePath: \robot-management-web\src\layout\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div :class="classObj" class="app-wrapper">
    <div
      v-if="device === 'mobile' && sidebar.opened"
      class="drawer-bg"
      @click="handleClickOutside"
    />
    <LayoutSidebar class="sidebar-container" />
    <div class="main-container">
      <div :class="{ 'fixed-header': fixedHeader }">
        <navbar />
        <TagsView />
      </div>
      <app-main />
    </div>
  </div>
</template>

<script setup>
import { useAppStore } from "@/store/app.js";
import { useSettingStore } from "@/store/settings.js";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import Navbar from "./components/LayoutNavbar.vue";
import AppMain from "./components/AppMain.vue";
import TagsView from "./components/TagsView/TagsView.vue";
import LayoutSidebar from "./components/Sidebar/LayoutSidebar.vue";

const appStore = useAppStore();
const settingStore = useSettingStore();
const { sidebar, device } = storeToRefs(appStore);
const { fixedHeader } = storeToRefs(settingStore);

const classObj = computed(() => {
  return {
    hideSidebar: !sidebar.value.opened,
    openSidebar: sidebar.value.opened,
    withoutAnimation: sidebar.value.withoutAnimation,
    mobile: device.value === "mobile",
  };
});

function handleClickOutside() {
  appStore.closeSidebar(false);
}
</script>

<style lang="scss" scoped>
@import "@/styles/mixin.scss";
@import "@/styles/variables.module.scss";

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
</style>
