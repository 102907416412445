<!--
 * @Author: reiner850593913 lk850593913@gmail.com
 * @Date: 2022-08-27 21:20:59
 * @LastEditors: reiner850593913 lk850593913@gmail.com
 * @LastEditTime: 2022-08-28 09:42:08
 * @FilePath: \robot-management-web\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
