<!--
 * @Author: Reiner
 * @Date: 2022-06-21 14:20:34
 * @LastEditors: ReinerLau lk850593913@gmail.com
 * @LastEditTime: 2022-11-24 08:57:53
 * @FilePath: \robot-management-web\src\layout\components\Avator\index.vue
 * @Description: 头像区域
-->
<template>
  <div>
    <el-dropdown class="h-full" trigger="click">
      <div>
        <img src="@/assets/images/sidebar/logo.png" class="h-4/5 mt-1" />
        <i class="el-icon-caret-bottom" />
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <router-link to="/">
            <el-dropdown-item> 主页 </el-dropdown-item>
          </router-link>
          <el-dropdown-item>
            <div @click="handleChangePassword">修改密码</div>
          </el-dropdown-item>
          <el-dropdown-item divided @click="handleLogout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-dialog
      v-model="formVisible"
      title="修改密码"
      width="30%"
      :destroy-on-close="true"
      @close="handleClose"
    >
      <el-form
        ref="changePasswordForm"
        :model="formData"
        :rules="formRules"
        label-width="100px"
      >
        <el-form-item label="新密码" prop="password">
          <el-input
            v-model="formData.password"
            placeholder="请输入新密码"
            clearable
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="handleChangePassword"> 取消 </el-button>
        <el-button :loading="loading" type="primary" @click="handelConfirm">
          确定
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { useUserStore } from "@/store/user.js";
import { useRouter } from "vue-router";
import { reactive, ref } from "vue";
import { setPasswd } from "@/api/login.js";
import { ElMessage } from "element-plus";

const { logout } = useUserStore();
const router = useRouter();

/**
 * @description: 退出登录
 * @return {*}
 */
async function handleLogout() {
  await logout();
  router.push("/login");
}

// 修改密码相关 -------------------------------------------------------------
const formVisible = ref(false);
const formData = reactive({
  password: "",
});
const formRules = {
  password: [{ required: true, message: "请输入密码", trigger: "blur" }],
};
const changePasswordForm = ref(null);
const loading = ref(false);
/**
 * @description: 显示修改密码弹窗
 * @return {*}
 */
function handleChangePassword() {
  formVisible.value = !formVisible.value;
}
/**
 * @description: 确定修改
 * @return {*}
 */
function handelConfirm() {
  loading.value = true;
  changePasswordForm.value.validate(async (valid) => {
    if (!valid) return;
    const { code, message } = await setPasswd({ passwd: formData.password });
    loading.value = false;
    formVisible.value = false;
    if (code === 200) {
      ElMessage({
        type: "success",
        message,
        duration: 3 * 1000,
      });
    }
  });
}
/**
 * @description: 弹窗关闭
 * @return {*}
 */
function handleClose() {
  formData.password = "";
}
</script>
