import { defineStore } from "pinia";
import {
  login as loginApi,
  getInfo as getInfoApi,
  logout as logoutAPI,
} from "@/api/user.js";
import { getToken, setToken, removeToken } from "@/utils/auth.js";
import { ref } from "vue";
import { resetRouter } from "@/router/index.js";

export const useUserStore = defineStore("user", () => {
  const token = ref("");
  // 首次加载先从 cookie 获取 token
  token.value = getToken();
  /**
   * @description: 登录
   * @param {*} userInfo 用户名/密码
   * @return {*}
   */
  async function login(userInfo) {
    const { username, password } = userInfo;
    const { data } = await loginApi({ username: username.trim(), password });
    const tokenStr = data.tokenHead + data.token;
    // 存储 token 到 cookie 中
    setToken(tokenStr);
    token.value = tokenStr;
  }

  // 用户名
  const name = ref("");
  // 角色身份
  const roles = ref([]);
  /**
   * @description: 获取用户信息
   * @return {*}
   */
  async function getInfo() {
    const { data } = await getInfoApi(token.value);
    if (!data) return {};
    if (data.roles && data.roles.length > 0) {
      roles.value = data.roles;
    }
    name.value = data.username;
    return data;
  }

  /**
   * @description: 退出登录
   * @return {*}
   */
  async function logout() {
    await logoutAPI(token.value);
    removeToken();
    resetRouter();
    resetState();
  }

  function resetState() {
    token.value = "";
    name.value = "";
    roles.value = [];
  }

  return {
    login,
    token,
    name,
    getInfo,
    roles,
    logout,
  };
});
