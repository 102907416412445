/*
 * @Author: reiner850593913 lk850593913@gmail.com
 * @Date: 2022-08-27 21:20:59
 * @LastEditors: ReinerLau lk850593913@gmail.com
 * @LastEditTime: 2022-10-17 13:02:49
 * @FilePath: \robot-management-web\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import { createPinia } from "pinia";
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import VueKonva from "vue-konva";
import FormCreate from "@form-create/element-ui";

import "@/styles/element/index.scss";
import "normalize.css/normalize.css";
import "@/styles/index.scss";

import "virtual:svg-icons-register";
import SvgIcon from "@/components/SvgIcon.vue";

const app = createApp(App);
app.use(router);
app.use(createPinia());
app.use(ElementPlus, {
  locale: zhCn,
});
app.use(VueKonva);
app.use(FormCreate);
app.component("SvgIcon", SvgIcon);

app.mount("#app");

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

import "@/permission/index.js"; // 路由跳转前的权限验证
import registerHas from "@/permission/has.js"; // 按钮控制权限指令
registerHas(app);
