/*
 * @Author: reiner850593913 lk850593913@gmail.com
 * @Date: 2022-10-30 12:01:51
 * @LastEditors: reiner850593913 lk850593913@gmail.com
 * @LastEditTime: 2022-10-30 12:10:13
 * @FilePath: \robot-management-web\src\router\modules\disinfect.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Layout from "@/layout/PageLayout.vue";

export default {
  path: "/disinfect",
  name: "disinfect",
  component: Layout,
  redirect: "/disinfect/robot-manage",
  meta: { title: "防疫机器人", icon: "Umbrella" },
  children: [
    {
      path: "robot-manage",
      name: "disinfect-robot-manage",
      component: () => import("@/views/disinfect/RobotManage/RobotManage.vue"),
      meta: { title: "机器人管理", icon: "Setting" },
    },
    {
      path: "robot-details",
      name: "disinfect-robot-details",
      component: () => import("@/views/disinfect/RobotInfo/RobotDetails.vue"),
      meta: { title: "机器人详情", hidden: true },
    },
    {
      path: "robot-info",
      name: "disinfect-robot-info",
      component: () => import("@/views/disinfect/RobotInfo/RobotInfo.vue"),
      meta: { title: "机器人信息", icon: "Postcard" },
    },
    {
      path: "map-setting",
      name: "disinfect-map-setting",
      component: () =>
        import("@/views/disinfect/MapSetting/MapSettingIndex.vue"),
      meta: { title: "地图设置", icon: "EditPen" },
    },
    {
      path: "notification",
      name: "disinfect-alarm-notification",
      meta: {
        title: "告警通知",
        icon: "Key",
      },
      children: [
        {
          path: "strategy",
          name: "disinfect-alarm-notification-strategy",
          component: () =>
            import("@/views/disinfect/AlarmNotice/AlarmStrategy.vue"),
          meta: {
            title: "告警策略",
            icon: "User",
          },
        },
        {
          path: "user",
          name: "aadmin-alarm-notification-user",
          component: () =>
            import("@/views/disinfect/AlarmNotice/AlarmUser.vue"),
          meta: {
            title: "告警联系人",
            icon: "User",
          },
        },
      ],
    },
    {
      path: "task-manage",
      name: "disinfect-task-manage",
      component: () => import("@/views/disinfect/TaskManage/TaskManage.vue"),
      meta: {
        title: "消毒任务",
        icon: "Edit",
      },
    },
    {
      path: "log-center",
      name: "disinfect-log-center",
      component: () => import("@/views/disinfect/LogCenter/LogCenter.vue"),
      meta: { title: "日志中心", icon: "Notebook" },
    },
    {
      path: "fault-history",
      name: "disinfect-fault-history",
      component: () => import("@/views/disinfect/LogCenter/FaultHistory.vue"),
      meta: { title: "故障历史", hidden: true },
    },
    {
      path: "map-management",
      name: "disinfect-map-management",
      meta: {
        title: "地图管理",
        icon: "Location",
      },
      children: [
        {
          path: "building",
          name: "disinfect-map-management-building",
          component: () =>
            import("@/views/disinfect/MapManage/BuildingManagement.vue"),
          meta: {
            title: "楼栋管理",
            icon: "OfficeBuilding",
          },
        },
        {
          path: "elevator",
          name: "disinfect-map-management-elevator",
          component: () =>
            import("@/views/disinfect/MapManage/ElevatorManagement.vue"),
          meta: {
            title: "电梯管理",
            icon: "OfficeBuilding",
          },
        },
        {
          path: "elevator-list",
          name: "disinfect-map-elevator-list",
          component: () =>
            import("@/views/disinfect/MapManage/components/ElevatorList.vue"),
          meta: {
            title: "电梯列表",
            icon: "OfficeBuilding",
            hidden: true,
          },
        },
        {
          path: "building-details",
          name: "disinfect-map-building-details",
          component: () =>
            import(
              "@/views/disinfect/MapManage/components/BuildingDetails.vue"
            ),
          meta: {
            title: "楼栋详情",
            icon: "OfficeBuilding",
            hidden: true,
          },
        },
      ],
    },
  ],
};
