<!--
 * @Author: Reiner
 * @Date: 2022-04-08 14:54:07
 * @LastEditors: ReinerLau lk850593913@gmail.com
 * @LastEditTime: 2022-09-05 08:48:31
 * @FilePath: \robot-management-web\src\layout\components\Navbar.vue
 * @Description: 导航栏
-->
<template>
  <div class="navbar">
    <hamburger
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggle-click="toggleSideBar"
    />
    <div class="right-menu">
      <Avator class="right-menu-item hover-effect" />
    </div>
  </div>
</template>

<script setup>
import { useAppStore } from "@/store/app.js";
import { storeToRefs } from "pinia";
import Hamburger from "./NavbarHamburger.vue";
import Avator from "./NavbarAvator.vue";

const appStore = useAppStore();
const { sidebar } = storeToRefs(appStore);

function toggleSideBar() {
  appStore.toggleSideBar();
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
}
</style>
