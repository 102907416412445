/*
 * @Author: 明明 343438055@qq.com
 * @Date: 2021-11-04 11:42:32
 * @LastEditors: hye0228 1261769443@qq.com
 * @LastEditTime: 2022-07-07 16:26:14
 * @FilePath: \robot-management-web\src\api\login.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from "@/utils/request";

export function login(username, password) {
  return request({
    url: "/admin/v1/login",
    method: "post",
    data: {
      username,
      password,
    },
  });
}

export function getInfo() {
  return request({
    url: "/admin/v1/info",
    method: "get",
  });
}

export function getOneInfo(id) {
  return request({
    url: "/admin/v1/admin/getInfo/" + id,
    method: "get",
  });
}

export function logout() {
  return request({
    url: "/admin/v1/logout",
    method: "post",
  });
}

export function fetchList(params) {
  return request({
    url: "/admin/v1/list",
    method: "get",
    params: params,
  });
}

export function createAdmin(data) {
  return request({
    url: "/admin/v1/register",
    method: "post",
    data: data,
  });
}

export function updateAdmin(id, data) {
  return request({
    url: "/admin/v1/update/" + id,
    method: "post",
    data: data,
  });
}

export function updateStatus(id, params) {
  return request({
    url: "/admin/v1/updateStatus/" + id,
    method: "post",
    params: params,
  });
}

export function deleteAdmin(id) {
  return request({
    url: "/admin/v1/delete/" + id,
    method: "post",
  });
}

export function getRoleByAdmin(id) {
  return request({
    url: "/admin/v1/role/" + id,
    method: "get",
  });
}

export function allocRole(data) {
  return request({
    url: "/admin/v1/role/update",
    method: "post",
    data: data,
  });
}

export function bindingCompany(cid, adminIds) {
  return request({
    url: "/admin/v1/bindingCompany",
    method: "post",
    data: "adminIds=" + adminIds + "&companyId=" + cid,
  });
}

export function setPasswd(params) {
  return request({
    url: "/admin/v1/setPasswd",
    method: "post",
    params,
  });
}
