<!--
 * @Author: ReinerLau lk850593913@gmail.com
 * @Date: 2022-08-23 10:45:57
 * @LastEditors: ReinerLau lk850593913@gmail.com
 * @LastEditTime: 2023-01-09 17:30:05
 * @FilePath: \robot\src\layout\components\Sidebar\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="select-none" :class="{ 'has-logo': showLogo }">
    <logo v-if="showLogo" :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        data-test="sidebar-menu"
        :default-active="activeMenu"
        :collapse="isCollapse"
        :background-color="menuBg"
        :text-color="menuText"
        :collapse-transition="false"
      >
        <sidebar-item v-for="route in routes" :key="route.name" :item="route" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: "LayoutSidebar",
};
</script>
<script setup>
import { useSettingStore } from "@/store/settings.js";
import { storeToRefs } from "pinia";
import { useAppStore } from "@/store/app.js";
import { computed } from "vue";
import Logo from "./Logo.vue";
import { useRoute, useRouter } from "vue-router";
import { menuBg, menuText } from "@/styles/variables.module.scss";
import SidebarItem from "./SidebarItem.vue";
import { constRoutes, asyncRoutes } from "@/router/index.js";

const { sidebarLogo: showLogo } = storeToRefs(useSettingStore());
const { sidebar } = useAppStore();
// 是否被折叠
const isCollapse = computed(() => !sidebar.opened);

// 菜单相关 ---------------------------------------------------------------
// 当前激活的路由
const activeMenu = computed(() => {
  const route = useRoute();
  const { meta, name } = route;
  // 如果路由 meta 信息中设置了 activeMenu 默认高亮的路径
  return meta.activeMenu || name;
});

const router = useRouter();
const routes = computed(() => {
  return (
    router
      .getRoutes()
      // .filter((route) => !route.meta.hidden)
      .filter(
        (route) =>
          constRoutes.find((constRoute) => constRoute.name === route.name) ||
          asyncRoutes.find((asyncRoute) => asyncRoute.name === route.name)
      )
  );
});
</script>
