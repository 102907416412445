<!--
 * @Author: reiner850593913 103234074+reiner850593913@users.noreply.github.com
 * @Date: 2022-05-09 13:56:35
 * @LastEditors: ReinerLau lk850593913@gmail.com
 * @LastEditTime: 2022-09-05 15:39:43
 * @FilePath: \robot-management-web\src\layout\components\TagsView\ScrollPane.vue
 * @Description: 标签页滚动组件
-->
<template>
  <el-scrollbar
    ref="scrollContainer"
    class="scroll-container"
    @wheel.prevent="handleScroll"
  >
    <slot />
  </el-scrollbar>
</template>

<script setup>
function handleScroll() {
  // console.log("test");
}
</script>

<!-- <script>
// 标签间隔
const tagAndTagSpacing = 4

export default {
  name: 'ScrollPane',
  computed: {
    scrollWrapper() {
      return this.$refs.scrollContainer.$refs.wrap
    }
  },
  mounted() {
    this.scrollWrapper.addEventListener('scroll', this.emitScroll, true)
  },
  beforeDestroy() {
    this.scrollWrapper.removeEventListener('scroll', this.emitScroll)
  },
  methods: {
    /**
     * @description: 滚动事件
     * @param {*} e 滚动事件对象
     * @return {*}
     */
    handleScroll(e) {
      const eventDelta = e.wheelDelta || -e.deltaY * 40
      const $scrollWrapper = this.scrollWrapper
      $scrollWrapper.scrollLeft = $scrollWrapper.scrollLeft + eventDelta / 4
    },
    /**
     * @description: 滚动到当前标签
     * @param {*} currentTag 当前标签
     * @return {*}
     */
    moveToTarget(currentTag) {
      const $container = this.$refs.scrollContainer.$el
      const $containerWidth = $container.offsetWidth
      const $scrollWrapper = this.scrollWrapper
      const tagList = this.$parent.$refs.tag

      let firstTag = null
      let lastTag = null

      // 找到第一个标签和最后一个标签
      if (tagList.length > 0) {
        firstTag = tagList[0]
        lastTag = tagList[tagList.length - 1]
      }

      if (firstTag === currentTag) {
        $scrollWrapper.scrollLeft = 0
      } else if (lastTag === currentTag) {
        $scrollWrapper.scrollLeft = $scrollWrapper.scrollWidth - $containerWidth
      } else {
        // 找到前一个标签和后一个标签
        const currentIndex = tagList.findIndex(item => item === currentTag)
        const prevTag = tagList[currentIndex - 1]
        const nextTag = tagList[currentIndex + 1]

        // 当前标签相对于下一个标签的左偏移
        const afterNextTagOffsetLeft = nextTag.$el.offsetLeft + nextTag.$el.offsetWidth + tagAndTagSpacing

        // 当前标签相对于前一个标签的右偏移
        const beforePrevTagOffsetLeft = prevTag.$el.offsetLeft - tagAndTagSpacing

        if (afterNextTagOffsetLeft > $scrollWrapper.scrollLeft + $containerWidth) {
          $scrollWrapper.scrollLeft = afterNextTagOffsetLeft - $containerWidth
        } else if (beforePrevTagOffsetLeft < $scrollWrapper.scrollLeft) {
          $scrollWrapper.scrollLeft = beforePrevTagOffsetLeft
        }
      }
    },
    /**
     * @description: 触发滚动关闭右键菜单
     * @param {*}
     * @return {*}
     */
    emitScroll() {
      this.$emit('scroll')
    }
  }
}
</script> -->

<style lang="scss" scoped>
:deep(.scroll-container) {
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  width: 100%;
  .el-scrollbar__bar {
    bottom: 0px;
  }
  .el-scrollbar__wrap {
    height: 49px;
  }
}
</style>
